<template>
  <div>
    <div :style="`width:100%;height:100px;`"> </div>
    <CookieDialog />
    <CarouselSlide />
    <AboutUs />
    <MapComponent />
    <FunctionalityComponent />
    <ProductsSimplified />
    <CatalogSimplified />
   
    <BlogSimplified />
    <NewsletterComponent class="mb-7"/>
    <TestimonialComponent />
    <ContactUs />
    <WhatsappBtn />
    <JivoComponent />
  </div>
</template>

<script>

import VisitorsService from "../services/VisitorsService"
export default {
  name: 'HomeView',

  components: {
    WhatsappBtn: () => import("../components/home/WhatsappBtn.vue"),
    ContactUs: () => import("../components/home/ContactUs.vue"),
    CarouselSlide: () => import("../components/home/CarouselSlide.vue"),
    CookieDialog: () => import("../components/core/CookieDialog.vue"),
    AboutUs: () => import("../components/home/AboutUs.vue"),
    TestimonialComponent: () => import("../components/home/TestimonialComponent.vue"),
    FunctionalityComponent: () => import("../components/home/FunctionalityComponent.vue"),
    CatalogSimplified: () => import("../components/home/CatalogSimplified.vue"),
    ProductsSimplified: () => import("../components/home/ProductsSimplified.vue"),
     BlogSimplified: () => import("../components/home/BlogSimplified.vue"),
     NewsletterComponent: () => import("../components/home/NewsletterComponent.vue"),
     JivoComponent: () => import("../components/home/JivoComponent.vue"),
    MapComponent: () => import("../components/home/MapComponent.vue"),
  },
  async mounted() {
    document.documentElement.scrollTop = 0;
    VisitorsService.add().then(
      () => { },
      () => { }
    );
  },
}
</script>
