<template>
  <v-app>
    <v-main>
      <v-main>
        <Navbar />
        <router-view />
      </v-main>
    </v-main>
  <Footer/>
  </v-app>
</template>

<script>

export default {
  name: "app",
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || "Brudine";
      },
    },
  },
  components: {
    Navbar: ()=> import("./components/core/NavBar.vue"),
    Footer: ()=> import("./components/core/FooterBar.vue"),
  },
  data() {
    return {};
  },
};
</script>
