import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from './store'
import i18n from "../src/components/core/i18n"
import scrollanimation from './directives/scrollanimation'
import '@mdi/font/css/materialdesignicons.css' //required for get list mdi Icon

import AxiosMiddleware from './middlewares/AxiosMiddleware'
import {VueEditor} from "vue2-editor";
import VueIconPicker from "v-icon-picker";

Vue.use(VueIconPicker);
Vue.directive('scrollanimation', scrollanimation);
AxiosMiddleware.action();
new Vue({
  router,
  vuetify,
  VueEditor,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
