import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#000',
                secondary: '#fff',
                simplicity1:'#f2f2f2',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
                gold:"#c79d33",
                grey1:"#7A7A7A",
                lightgrey: '#ebebeb',
                lightgrey2: '#F2F2F2'
            },
            dark: {
                primary: '#7952b3',
                simplicity1:'#fcf2ed',
                secondary: '#7711f7',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
                lightgrey: '#ebebeb',
                lightgrey2: '#F2F2F2'
            }
        }
    },
    icons: {
        iconfont: 'mdi'
    }
});
