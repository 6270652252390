import axios from "axios"
import APIConstants from "../constants/APIConstants"
import LocalStorageService from "./LocalStorageService"

export default class VisitorsService {

    static add() {
        return axios({
            url: APIConstants.baseURL + "visitors/add",
            method: "POST",
        });
    }

    static paginated(page,startDate,endDate) {
        return axios({
            url: APIConstants.baseURL + `visitors/paginated/${page}/${startDate}/${endDate}`,
            method: "GET",
            headers: {
                Authorization: LocalStorageService.getToken()
            },
        });
    }

}
