import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import( '../views/LoginView.vue'),
    meta: { title: 'Login' }
  },
  {
    path: '/esqueci-minha-senha/:token',
    name: 'Esqueci a senha',
    component: () => import( '../views/ForgotPasswordView.vue'),
    meta: { title: 'Esqueci a senha'}
  },
  {
    path: '/product/:id',
    name: 'Product',
    component: () => import( '../views/ProductView.vue'),
    meta: { title: 'Product' }
  },
  {
    path: '/criar-conta',
    name: 'Criar conta',
    component: () => import( '../views/CreateAccountView.vue'),
    meta: { title: 'Criar conta' }
  },
  {
    path: '/products',
    name: 'Products',
    component: () => import( '../views/ProductsView.vue'),
    meta: { title: 'Products' }
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import( '../views/FAQView.vue'),
    meta: { title: 'FAQ' }
  },
  {
    path: '/products/:category',
    name: 'Products category',
    component: () => import( '../views/ProductsView.vue'),
    meta: { title: 'Product' }
  },
  {
    path: '/dashboard/blog',
    name: 'Blog Dashboard',
    component: () => import( '../views/backoffice/PublicationBO.vue'),
    meta: { title: 'Admin Blog' }
  },
  {
    path: '/dashboard/budgets',
    name: 'Orçamentos',
    component: () => import( '../views/backoffice/BudgetBO.vue'),
    meta: { title: 'Admin Blog' }
  },
  {
    path: '/dashboard/produtos',
    name: 'Produtos Dashboard',
    component: () => import( '../views/backoffice/ProductBO.vue'),
    meta: { title: 'Admin Blog' }
  },
  {
    path: '/dashboard/categorias',
    name: 'Produtos categorias',
    component: () => import( '../views/backoffice/CategoryBO.vue'),
    meta: { title: 'Admin Blog' }
  },
  {
    path: '/dashboard/faq',
    name: 'Faq',
    component: () => import( '../views/backoffice/FaqBO.vue'),
    meta: { title: 'Admin faq' }
  },
  {
    path: '/dashboard/mapa',
    name: 'Mapa',
    component: () => import( '../views/backoffice/MapBO'),
    meta: { title: 'Admin Map' }
  },
  {
    path: '/dashboard/slide',
    name: 'Slide',
    component: () => import( '../views/backoffice/SlideBO.vue'),
    meta: { title: 'Admin slide' }
  },
  {
    path: '/dashboard/catalogo',
    name: 'Catalogo',
    component: () => import( '../views/backoffice/CatalogBO.vue'),
    meta: { title: 'Admin catalogo' }
  },
  {
    path: '/dashboard/funil',
    name: 'Funil Dashboard',
    component: () => import( '../views/backoffice/MetricsBO.vue'),
    meta: { title: 'Admin funil' }
  },
  {
    path: '/blog/:identifier',
    name: 'Blog publication',
    component: () => import( '../views/PublicationView.vue'),
    meta: { title: 'Publication' }
  },
  {
    path: '/catalog',
    name: 'Catalog',
    component: () => import( '../views/CatalogView.vue'),
    meta: { title: 'Catalog' }
  },
  {
    path: '/blog',
    name: 'Blog',
    component: () => import( '../components/home/PostGrid.vue'),
    meta: { title: 'Blog' }
  },
  {
    path: '/unsubscribe/:id',
    name: 'Unsubscribe',
    component: () => import( '../views/UnsubscribeView.vue'),
    meta: { title: 'Blog' }
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
