import axios from 'axios';
import LocalStorageService from "../services/LocalStorageService"


export default class AxiosMiddleware {
    static action() {
        axios.interceptors.response.use((res) => {
            return res;
        }, (err) => {
            if (err.response.status == 401) {
                LocalStorageService.killSession();
                window.location.href = "/login";
            }
            return Promise.reject(err);
        });
    }
}
