import en from "../../data/en.json"
import es from "../../data/es.json"
import Vue from 'vue'
import VueI18n from "vue-i18n"

Vue.use(VueI18n);

export default new VueI18n ({
    locale: localStorage.getItem('idiom') || 'en',
    messages: {
        en: en,
        es: es
    }
})


